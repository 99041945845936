import { Stack } from "@mui/material";
import { STAKING_POOL_CONFIG } from "../../config";
import {
  CardBody,
  CardHeading,
  CardRow,
  CardSubHeading,
  CardText,
  Hr,
} from "../../styles/CardStyles";
import { FC } from "react";
import CountDownText from "../../components/CountDownText";
import BigNumber from "bignumber.js";
import { BIG_10 } from "../../utils/formatWeiToEth";

interface IHeroCard {
  account: string | null | undefined;
  nextHarvest: string | null;
  totalInputTokensStaked: string;
  apr: string | null;
}

const HeroCard: FC<IHeroCard> = ({
  account,
  nextHarvest,
  totalInputTokensStaked,
  apr,
}) => {
  const nextHarvestTimeInMilliseconds = nextHarvest
    ? parseFloat(nextHarvest) * 1000
    : null;

  const priceOfInputToken = STAKING_POOL_CONFIG.inputToken.price;
  const totalLiquidity = new BigNumber(totalInputTokensStaked)
    .dividedBy(BIG_10.pow(STAKING_POOL_CONFIG.inputToken.decimals))
    .multipliedBy(priceOfInputToken ? priceOfInputToken : "1")
    .toFixed(2);

  const apy = new BigNumber(
    apr ? apr : STAKING_POOL_CONFIG.rewardToken.expectedAPR
  ).toFixed(2);

  return (
    <Stack>
      <CardHeading>
        {STAKING_POOL_CONFIG.inputToken.name} Staking Pool
      </CardHeading>
      <Hr />
      <CardBody>
        <CardRow>
          <CardSubHeading>Current APR</CardSubHeading>
          <CardText>{apy}%</CardText>
        </CardRow>
        <CardRow>
          <CardSubHeading>Staked Token</CardSubHeading>
          <CardText>{STAKING_POOL_CONFIG.inputToken.symbol}</CardText>
        </CardRow>
        <CardRow>
          <CardSubHeading>Reward Token</CardSubHeading>
          <CardText>{STAKING_POOL_CONFIG.rewardToken.symbol}</CardText>
        </CardRow>
        <CardRow>
          <CardSubHeading>TVL (AUD)</CardSubHeading>
          <CardText>${totalLiquidity}</CardText>
        </CardRow>
        <CardRow>
          <CardSubHeading>Withdrawal fee</CardSubHeading>
          <CardText>
            {Number(STAKING_POOL_CONFIG.withdrawalFee) === 0
              ? `FREE`
              : `${parseFloat(STAKING_POOL_CONFIG.withdrawalFee) / 100}%`}
          </CardText>
        </CardRow>
        <CardRow>
          <CardSubHeading>Harvest lock interval</CardSubHeading>
          <CardText>{STAKING_POOL_CONFIG.harvestInterval} secs</CardText>
        </CardRow>
        {account && nextHarvest && nextHarvestTimeInMilliseconds && (
          <CardRow>
            <CardSubHeading>Next harvest in</CardSubHeading>
            <CountDownText time={nextHarvestTimeInMilliseconds} />
          </CardRow>
        )}
      </CardBody>
    </Stack>
  );
};

export default HeroCard;
