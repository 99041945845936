import React, { useState } from "react";
import { CardSubHeading, CardText } from "../styles/CardStyles";
import { Button, Stack, TextField } from "@mui/material";
import { STAKING_POOL_CONFIG } from "../config";
import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";
import poolAbi from "../config/abi/pool.json";
import { Pool } from "../config/abi/types";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import { OwnerCard } from "../views/StakingPool/PoolPage";

export const InputContainer = styled.div`
  position: relative;
`;

const UpdateApr = ({
  account,
  pendingTxn,
  setPendingTxn,
  handleConnectWalletModalOpen,
  currExpectedAPR,
}: {
  account: string;
  pendingTxn: boolean;
  setPendingTxn: React.Dispatch<React.SetStateAction<boolean>>;
  handleConnectWalletModalOpen: () => void;
  currExpectedAPR: string;
}) => {
  const [expectedApr, setExpectedApr] = useState("0");

  const aprFormatted = new BigNumber(currExpectedAPR).toFixed(2);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExpectedApr(event.target.value);
  };

  const handleUpdateExpectedApr = async () => {
    setPendingTxn(true);
    try {
      const provider = new ethers.providers.Web3Provider(
        // @ts-ignore
        (window as WindowChain).ethereum
      );
      const signer = provider.getSigner();
      const poolContract = (await new Contract(
        STAKING_POOL_CONFIG.id,
        poolAbi,
        signer
      )) as Pool;

      const expectedRewardTokenAPR = new BigNumber(10)
        .pow(16)
        .multipliedBy(expectedApr)
        .toFixed();

      const rewardTokenRateInUSD = STAKING_POOL_CONFIG.rewardToken.price;
      const inputTokenRateInUSD = STAKING_POOL_CONFIG.inputToken.price;

      const calculatedAPR = new BigNumber(expectedRewardTokenAPR)
        .multipliedBy(inputTokenRateInUSD)
        .dividedBy(rewardTokenRateInUSD)
        .toFixed();

      const txn = await poolContract.updateExpectedAPR(calculatedAPR, "0");

      txn.wait();
      setPendingTxn(false);
    } catch (err) {
      setPendingTxn(false);
      console.error("Error while changing expected APR: ", err);
    }
  };

  return (
    <OwnerCard>
      <Stack rowGap={3}>
        <CardText>Update Expected APR</CardText>
        {account && (
          <>
            <Stack direction={"row"} justifyContent={"center"}>
              <CardSubHeading>Current expected APR: </CardSubHeading>
              <CardText style={{ margin: "0 8px" }}>{aprFormatted}%</CardText>
            </Stack>
            <Stack direction={"row"} justifyContent={"center"}>
              <CardSubHeading>New expected APR: </CardSubHeading>
              <CardText style={{ margin: "0 8px" }}>{expectedApr}%</CardText>
            </Stack>
          </>
        )}
        <Stack>
          <InputContainer>
            <TextField
              label={"Update expected APR"}
              value={expectedApr}
              placeholder={"Enter expected APR"}
              variant={"outlined"}
              onChange={handleInputChange}
              size={"medium"}
              aria-placeholder={"0.0"}
              fullWidth
            />
          </InputContainer>
        </Stack>

        {account ? (
          <Button
            variant={"contained"}
            disabled={pendingTxn}
            onClick={handleUpdateExpectedApr}
          >
            Update Expected APR
          </Button>
        ) : (
          <Button variant={"outlined"} onClick={handleConnectWalletModalOpen}>
            Connect to Wallet
          </Button>
        )}
      </Stack>
    </OwnerCard>
  );
};

export default UpdateApr;
