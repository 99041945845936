import React, { useState } from "react";
import { CardText } from "../styles/CardStyles";
import { Button, Stack, TextField } from "@mui/material";
import { STAKING_POOL_CONFIG } from "../config";
import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";
import b4rcAbi from "../config/abi/b4rc.json";
import BigNumber from "bignumber.js";
import styled from "styled-components";
import { B4rc } from "../config/abi/types";
import { OwnerCard } from "../views/StakingPool/PoolPage";

export const InputContainer = styled.div`
  position: relative;
`;

const Mint = ({
  account,
  pendingTxn,
  setPendingTxn,
  handleConnectWalletModalOpen,
  owner,
}: {
  account: string;
  pendingTxn: boolean;
  setPendingTxn: React.Dispatch<React.SetStateAction<boolean>>;
  handleConnectWalletModalOpen: () => void;
  owner: string;
}) => {
  const [mintAmount, setMintAmount] = useState("0");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMintAmount(event.target.value);
  };

  const handleMint = async () => {
    setPendingTxn(true);
    try {
      const provider = new ethers.providers.Web3Provider(
        // @ts-ignore
        (window as WindowChain).ethereum
      );
      const signer = provider.getSigner();
      const b4rcContract = (await new Contract(
        STAKING_POOL_CONFIG.rewardToken.address,
        b4rcAbi,
        signer
      )) as B4rc;

      const mintAmountInWei = new BigNumber(mintAmount)
        .multipliedBy(
          new BigNumber(10).pow(STAKING_POOL_CONFIG.rewardToken.decimals)
        )
        .toFixed();

      const txn = await b4rcContract.mint(owner, mintAmountInWei);

      txn.wait();
      setPendingTxn(false);
    } catch (err) {
      setPendingTxn(false);
      console.error("Error while minting reward token: ", err);
    }
  };

  return (
    <OwnerCard style={{ marginBottom: "10px" }}>
      <Stack rowGap={3}>
        <CardText>Mint</CardText>
        <Stack>
          <InputContainer>
            <TextField
              label={"Mint amount"}
              value={mintAmount}
              placeholder={"Reward token amount to mint"}
              variant={"outlined"}
              onChange={handleInputChange}
              size={"medium"}
              aria-placeholder={"0.0"}
              fullWidth
            />
          </InputContainer>
        </Stack>

        {account ? (
          <Button
            variant={"contained"}
            disabled={pendingTxn}
            onClick={handleMint}
          >
            Mint reward token
          </Button>
        ) : (
          <Button variant={"outlined"} onClick={handleConnectWalletModalOpen}>
            Connect to Wallet
          </Button>
        )}
      </Stack>
    </OwnerCard>
  );
};

export default Mint;
