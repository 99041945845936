import React from "react";
import { Container } from "@mui/material";
import styled from "styled-components";
import PoolPage from "./PoolPage";

const BlackContainer = styled.div`
  background-color: ${(props) => props.theme.palette.background.default};
  padding-bottom: 20px;
`;

const StyledContainer = styled(Container)``;

const StakingPool = ({
  handleConnectWalletModalOpen,
}: {
  handleConnectWalletModalOpen: () => void;
}) => {
  return (
    <BlackContainer>
      <StyledContainer>
        <PoolPage handleConnectWalletModalOpen={handleConnectWalletModalOpen} />
      </StyledContainer>
    </BlackContainer>
  );
};

export default StakingPool;
