import React, { useState } from "react";
import { CardSubHeading, CardText } from "../styles/CardStyles";
import { Button, Stack, TextField } from "@mui/material";
import { STAKING_POOL_CONFIG } from "../config";
import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";
import poolAbi from "../config/abi/pool.json";
import { Pool } from "../config/abi/types";
import styled from "styled-components";
import { OwnerCard } from "../views/StakingPool/PoolPage";

export const InputContainer = styled.div`
  position: relative;
`;

const UpdateHarvestInterval = ({
  account,
  pendingTxn,
  setPendingTxn,
  handleConnectWalletModalOpen,
}: {
  account: string;
  pendingTxn: boolean;
  setPendingTxn: React.Dispatch<React.SetStateAction<boolean>>;
  handleConnectWalletModalOpen: () => void;
}) => {
  const [harvestInterval, setHarvestInterval] = useState("0");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHarvestInterval(event.target.value);
  };

  const handleUpdateHarvestInterval = async () => {
    setPendingTxn(true);
    try {
      const provider = new ethers.providers.Web3Provider(
        // @ts-ignore
        (window as WindowChain).ethereum
      );
      const signer = provider.getSigner();
      const poolContract = (await new Contract(
        STAKING_POOL_CONFIG.id,
        poolAbi,
        signer
      )) as Pool;

      const txn = await poolContract.updateHarvestInterval(
        harvestInterval,
        true
      );

      txn.wait();
      setPendingTxn(false);
    } catch (err) {
      setPendingTxn(false);
      console.error("Error while updating harvest interval: ", err);
    }
  };

  return (
    <OwnerCard>
      <Stack rowGap={3}>
        <CardText>Update Harvest Interval</CardText>
        {account && (
          <>
            <Stack direction={"row"} justifyContent={"center"}>
              <CardSubHeading>Current harvest interval: </CardSubHeading>
              <CardText style={{ margin: "0 8px" }}>
                {STAKING_POOL_CONFIG.harvestInterval} secs
              </CardText>
            </Stack>
            <Stack direction={"row"} justifyContent={"center"}>
              <CardSubHeading>New harvest interval: </CardSubHeading>
              <CardText style={{ margin: "0 8px" }}>
                {harvestInterval} secs
              </CardText>
            </Stack>
          </>
        )}
        <Stack>
          <InputContainer>
            <TextField
              label={"Update harvest interval"}
              value={harvestInterval}
              placeholder={"Enter harvest interval"}
              variant={"outlined"}
              onChange={handleInputChange}
              size={"medium"}
              aria-placeholder={"0.0"}
              fullWidth
            />
          </InputContainer>
        </Stack>

        {account ? (
          <Button
            variant={"contained"}
            disabled={pendingTxn}
            onClick={handleUpdateHarvestInterval}
          >
            Update harvest interval
          </Button>
        ) : (
          <Button variant={"outlined"} onClick={handleConnectWalletModalOpen}>
            Connect to Wallet
          </Button>
        )}
      </Stack>
    </OwnerCard>
  );
};

export default UpdateHarvestInterval;
