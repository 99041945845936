import { Button } from "@mui/material";

const ConnectWalletBtn = ({
  handleConnectWalletModalOpen,
}: {
  handleConnectWalletModalOpen: () => void;
}) => {
  return (
    <Button
      variant={"outlined"}
      onClick={handleConnectWalletModalOpen}
      fullWidth
    >
      Connect to wallet
    </Button>
  );
};

export default ConnectWalletBtn;
