import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { CardText } from "../styles/CardStyles";
import { Button, Stack, TextField } from "@mui/material";
import { STAKING_POOL_CONFIG } from "../config";
import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";
import erc20Abi from "../config/abi/erc20.json";
import BigNumber from "bignumber.js";
import styled from "styled-components";
import { Erc20 } from "../config/abi/types";
import { OwnerCard } from "../views/StakingPool/PoolPage";
import { LoadingButton } from "@mui/lab";
import { getERC20Contract } from "../utils/contractHelpers";

export const InputContainer = styled.div`
  position: relative;
`;

const TransferRewardToken = ({
  account,
  pendingTxn,
  setPendingTxn,
  handleConnectWalletModalOpen,
}: {
  account: string;
  pendingTxn: boolean;
  setPendingTxn: Dispatch<SetStateAction<boolean>>;
  handleConnectWalletModalOpen: () => void;
}) => {
  const [rewardTokenAllowance, setRewardTokenAllowance] = useState("0");
  const [transferAmount, setTransferAmount] = useState("0");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTransferAmount(event.target.value);
  };

  const getRewardTokenAllowance = useCallback(async () => {
    const erc20Contract = getERC20Contract(
      STAKING_POOL_CONFIG.rewardToken.address
    );
    const allowance = await erc20Contract.allowance(
      account,
      STAKING_POOL_CONFIG.id
    );
    setRewardTokenAllowance(() => allowance.toString());
  }, [account]);

  const handleRewardTokenApprove = async () => {
    setPendingTxn(() => true);
    try {
      const amount = 1000000;
      const approvalAmount = ethers.utils.parseUnits(amount.toString(), 18);
      const provider = new ethers.providers.Web3Provider(
        // @ts-ignore
        (window as WindowChain).ethereum
      );
      const signer = provider.getSigner();
      const rewardTokenContract = new Contract(
        STAKING_POOL_CONFIG.rewardToken.address,
        erc20Abi,
        signer
      ) as Erc20;
      const approvalTx = await rewardTokenContract.approve(
        STAKING_POOL_CONFIG.id,
        approvalAmount
      );
      await approvalTx.wait();
      const allowanceAmount = await rewardTokenContract.allowance(
        account,
        STAKING_POOL_CONFIG.id
      );
      setRewardTokenAllowance(() => allowanceAmount.toString());
      setPendingTxn(() => false);
    } catch (error) {
      setPendingTxn(() => false);
      console.error("Error in handleRewardTokenApprove: ", error);
    }
  };

  const handleTransferRewardToken = async () => {
    setPendingTxn(true);
    try {
      const provider = new ethers.providers.Web3Provider(
        // @ts-ignore
        (window as WindowChain).ethereum
      );
      const signer = provider.getSigner();

      const erc20Contract = new Contract(
        STAKING_POOL_CONFIG.rewardToken.address,
        erc20Abi,
        signer
      ) as Erc20;

      const allowanceOfRewardToken = await erc20Contract.allowance(
        account,
        STAKING_POOL_CONFIG.id
      );

      const transferAmountInWei = new BigNumber(transferAmount)
        .multipliedBy(
          new BigNumber(10).pow(STAKING_POOL_CONFIG.rewardToken.decimals)
        )
        .toFixed();

      if (
        new BigNumber(allowanceOfRewardToken.toString()).isLessThan(
          transferAmountInWei
        )
      ) {
        const txn = await erc20Contract.approve(
          STAKING_POOL_CONFIG.id,
          transferAmountInWei
        );
        txn.wait();
      }

      const txn = await erc20Contract.transfer(
        STAKING_POOL_CONFIG.id,
        transferAmountInWei
      );

      txn.wait();
      setPendingTxn(false);
    } catch (err) {
      setPendingTxn(false);
      console.error("Error while transferring reward token: ", err);
    }
  };

  useEffect(() => {
    getRewardTokenAllowance().catch((e) =>
      console.log("Error in getRewardTokenAllowance: ", e)
    );
  }, [getRewardTokenAllowance]);

  return (
    <OwnerCard style={{ marginBottom: "10px" }}>
      <Stack rowGap={3}>
        <CardText>Transfer Reward Token</CardText>
        <Stack>
          <InputContainer>
            <TextField
              label={"Transfer amount"}
              value={transferAmount}
              placeholder={"Reward token amount to transfer"}
              variant={"outlined"}
              onChange={handleInputChange}
              size={"medium"}
              aria-placeholder={"0.0"}
              fullWidth
            />
          </InputContainer>
        </Stack>

        {account ? (
          <>
            {new BigNumber(rewardTokenAllowance).isGreaterThan(0) ? (
              <Button
                variant={"contained"}
                disabled={pendingTxn}
                onClick={handleTransferRewardToken}
              >
                Transfer reward token
              </Button>
            ) : (
              <LoadingButton
                variant={"outlined"}
                onClick={handleRewardTokenApprove}
                fullWidth
                loading={pendingTxn}
              >
                Connect asset
              </LoadingButton>
            )}
          </>
        ) : (
          <Button variant={"outlined"} onClick={handleConnectWalletModalOpen}>
            Connect to Wallet
          </Button>
        )}
      </Stack>
    </OwnerCard>
  );
};

export default TransferRewardToken;
