import {
  Hr,
  CardHeading,
  CardSubHeading,
  CardText,
  CardRow,
} from "../../styles/CardStyles";
import { Button, Stack } from "@mui/material";
import { ChangeEvent, FC, useState } from "react";
import { IUserPoolData, OwnerCard } from "./PoolPage";
import { STAKING_POOL_CONFIG } from "../../config";
import BigNumber from "bignumber.js";
import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers/lib.esm";
import poolAbi from "../../config/abi/pool.json";
import erc20Abi from "../../config/abi/erc20.json";
import { Pool, Erc20 } from "../../config/abi/types";
import formatWeiToEth, { BIG_10 } from "../../utils/formatWeiToEth";
import ConnectWalletBtn from "../../components/ConnectWalletBtn";
import StyledModal from "../../components/StyledModal";
import { LoadingButton } from "@mui/lab";
import TxnStateModal from "../../components/TxnStateModal";
import CopyToClipboard from "../../components/CopyToClipboard";

interface IStakingCard {
  account: string | null | undefined;
  userPoolData: IUserPoolData;
  pendingTxn: boolean;
  handleConnectWalletModalOpen: () => void;
  handleApprove: () => void;
}

const StakingCard: FC<IStakingCard> = ({
  account,
  userPoolData,
  pendingTxn,
  handleConnectWalletModalOpen,
  handleApprove,
}) => {
  const [amount, setAmount] = useState("0.0");
  const [stakeModalOpen, setStakeModalOpen] = useState(false);
  const [unstakeModalOpen, setUnstakeModalOpen] = useState(false);
  const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
  const [txnPending, setTxnPending] = useState({
    loading: false,
    success: false,
    failure: false,
  });

  const handleOpenPurchaseModal = () => setOpenPurchaseModal(true);
  const handleClosePurchaseModal = () => setOpenPurchaseModal(false);

  const handleStakeModalOpen = () => setStakeModalOpen(true);
  const handleUnstakeModalOpen = () => setUnstakeModalOpen(true);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };

  const handleStakeModalClose = () => setStakeModalOpen(false);
  const handleUnstakeModalClose = () => setUnstakeModalOpen(false);

  const handleStake = async () => {
    handleStakeModalClose();
    handleOpenPurchaseModal();
    setTxnPending({
      loading: true,
      success: false,
      failure: false,
    });
    try {
      const stakeAmount = new BigNumber(amount)
        .multipliedBy(BIG_10.pow(STAKING_POOL_CONFIG.inputToken.decimals))
        .toFixed();

      const provider = new ethers.providers.Web3Provider(
        // @ts-ignore
        (window as WindowChain).ethereum
      );
      const signer = provider.getSigner();
      const poolContract = new Contract(
        STAKING_POOL_CONFIG.id,
        poolAbi,
        signer
      ) as Pool;

      const erc20Contract = new Contract(
        STAKING_POOL_CONFIG.inputToken.address,
        erc20Abi,
        signer
      ) as Erc20;

      if (!account) return;

      const allowanceOfInputToken = await erc20Contract.allowance(
        account,
        STAKING_POOL_CONFIG.id
      );

      if (
        new BigNumber(allowanceOfInputToken.toString()).isLessThan(stakeAmount)
      ) {
        const txn = await erc20Contract.approve(
          STAKING_POOL_CONFIG.id,
          stakeAmount
        );
        await txn.wait();
      }

      const depositTx = await poolContract.deposit(stakeAmount);
      await depositTx.wait();
      setTxnPending({
        loading: false,
        success: true,
        failure: false,
      });
    } catch (err) {
      setTxnPending({
        loading: false,
        success: false,
        failure: true,
      });
      console.error("Error in handleStake: ", err);
    }
  };
  const handleUnstake = async () => {
    handleUnstakeModalClose();
    handleOpenPurchaseModal();
    setTxnPending({
      loading: true,
      success: false,
      failure: false,
    });
    try {
      const stakeAmount = new BigNumber(amount)
        .multipliedBy(BIG_10.pow(STAKING_POOL_CONFIG.inputToken.decimals))
        .toFixed();

      const provider = new ethers.providers.Web3Provider(
        // @ts-ignore
        (window as WindowChain).ethereum
      );
      const signer = provider.getSigner();
      const poolContract = new Contract(
        STAKING_POOL_CONFIG.id,
        poolAbi,
        signer
      ) as Pool;
      const withdrawTx = await poolContract.withdraw(stakeAmount);
      await withdrawTx.wait();
      setTxnPending({
        loading: false,
        success: true,
        failure: false,
      });
    } catch (err) {
      setTxnPending({
        loading: false,
        success: false,
        failure: true,
      });
      console.error("Error in handleUnstake: ", err);
    }
  };

  return (
    <>
      <TxnStateModal
        open={openPurchaseModal}
        handleClose={handleClosePurchaseModal}
        txnPending={txnPending}
      />
      {userPoolData && (
        <StyledModal
          heading={"Stake"}
          open={stakeModalOpen}
          handleClose={handleStakeModalClose}
          amount={amount}
          setAmount={setAmount}
          handleInputChange={handleInputChange}
          max={formatWeiToEth(
            userPoolData.inputTokenBalance,
            Number(STAKING_POOL_CONFIG.inputToken.decimals),
            6
          )}
          handleBtnClick={handleStake}
          pendingTxn={pendingTxn}
        />
      )}
      {userPoolData && (
        <StyledModal
          heading={"Unstake"}
          open={unstakeModalOpen}
          handleClose={handleUnstakeModalClose}
          amount={amount}
          setAmount={setAmount}
          handleInputChange={handleInputChange}
          max={formatWeiToEth(
            userPoolData.amount,
            Number(STAKING_POOL_CONFIG.inputToken.decimals),
            6
          )}
          handleBtnClick={handleUnstake}
          pendingTxn={pendingTxn}
        />
      )}
      <OwnerCard>
        <CardHeading>Staking Info</CardHeading>
        <Hr />
        <Stack>
          <Stack
            direction={"row"}
            display={"flex"}
            justifyContent={"space-around"}
            alignItems={"center"}
            marginBottom={"10px"}
            columnGap={1}
          >
            <Stack direction={"row"}>
              <img
                src={`${STAKING_POOL_CONFIG.inputToken.tokenURL}`}
                alt={`${STAKING_POOL_CONFIG.inputToken.name} token`}
                width={"30px"}
                height={"30px"}
              />

              <Stack rowGap={0.5} marginLeft={"5px"}>
                <CardText>{STAKING_POOL_CONFIG.inputToken.name}</CardText>
                <CardSubHeading>
                  {STAKING_POOL_CONFIG.inputToken.symbol}
                </CardSubHeading>
              </Stack>
            </Stack>

            <Stack
              display={"flex"}
              alignItems={"center"}
              fontSize={"18px"}
              fontWeight={600}
              direction={"row"}
            >
              <CardSubHeading>
                {`${STAKING_POOL_CONFIG.inputToken.address.slice(
                  0,
                  4
                )}...${STAKING_POOL_CONFIG.inputToken.address.slice(
                  STAKING_POOL_CONFIG.inputToken.address.length - 4
                )}`}
              </CardSubHeading>
              <CopyToClipboard
                toCopy={STAKING_POOL_CONFIG.inputToken.address}
              />
            </Stack>
          </Stack>
          {account ? (
            <>
              <CardRow>
                <CardSubHeading>
                  {STAKING_POOL_CONFIG.inputToken.symbol} balance
                </CardSubHeading>
                <CardText>
                  {formatWeiToEth(
                    userPoolData?.inputTokenBalance ?? "1",
                    Number(STAKING_POOL_CONFIG.inputToken.decimals)
                  )}
                </CardText>
              </CardRow>
              <CardRow style={{ marginBottom: "20px" }}>
                <CardSubHeading>
                  {STAKING_POOL_CONFIG.inputToken.symbol} staked
                </CardSubHeading>
                <CardText>
                  {formatWeiToEth(
                    userPoolData?.amount ?? "1",
                    Number(STAKING_POOL_CONFIG.inputToken.decimals),
                    3
                  )}
                </CardText>
              </CardRow>
              {new BigNumber(userPoolData?.allowance ?? "0").isGreaterThan(
                0
              ) ? (
                <Stack direction={"row"} gap={1}>
                  <Button
                    variant={"contained"}
                    fullWidth
                    disabled={pendingTxn}
                    onClick={handleStakeModalOpen}
                  >
                    Stake
                  </Button>
                  <Button
                    variant={"outlined"}
                    fullWidth
                    disabled={pendingTxn}
                    onClick={handleUnstakeModalOpen}
                  >
                    Unstake
                  </Button>
                </Stack>
              ) : (
                <LoadingButton
                  variant={"outlined"}
                  onClick={handleApprove}
                  fullWidth
                  loading={pendingTxn}
                >
                  Connect asset
                </LoadingButton>
              )}
            </>
          ) : (
            <ConnectWalletBtn
              handleConnectWalletModalOpen={handleConnectWalletModalOpen}
            />
          )}
        </Stack>
      </OwnerCard>
    </>
  );
};

export default StakingCard;
